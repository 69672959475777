<template>
  <div>
   <tutorial-component></tutorial-component>
  </div>
</template> 

<script>
import TutorialComponent from '../components/TutorialComponent.vue'
export default {
  components: {
    TutorialComponent
  }
}
</script>
