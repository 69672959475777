import Vue from 'vue';
import Login from '../views/Login.vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Users from '../views/Users.vue';
import Licenses from '../views/Licenses.vue';
import Products from '../views/Products.vue';
import Laboratories from '../views/Laboratories.vue';
import Substances from '../views/Substances.vue';
import PharmaForm from '../views/PharmaForm.vue';
import Clients from '../views/Clients.vue';
import Audit from '../views/Audit.vue';
import Reports from '../views/Reports.vue';
import Tutorial from '../views/Tutorial.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/licenses',
    component: Licenses,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/products',
    component: Products,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/laboratories',
    component: Laboratories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/substances',
    component: Substances,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pharma_form',
    component: PharmaForm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients',
    component: Clients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/audit',
    component: Audit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reports',
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tutorial',
    component: Tutorial,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!(localStorage.getItem('auth') ?? false) && to.name !== 'Login') {
    next('/');
  } else if (localStorage.getItem('auth') === 'true' && to.name === 'Login') {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
