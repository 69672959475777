<template>
  <div>
   <audit-component></audit-component>
  </div>
</template> 

<script>
import AuditComponent from '../components/AuditComponent.vue'
export default {
  components: {
    AuditComponent,
  }
}
</script>
