<template>
  <div
    class="myFont pa-0 login d-flex align-center align-sm-center align-md-center"
  >
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="justify-center pa-0 ma-0">
        <v-col
          cols="10"
          sm="6"
          md="6"
          lg="4"
          class="mt-0 mt-sm-10 mt-md-0 pa-0 contentCardLogin"
        >
          <v-card class="mt-0 mt-sm-16 pa-3 px-5 py-3 mb-5 cardLogin">
            <v-list-item class="pa-0">
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n12"
              >
                <v-sheet color="blue darken-4 rounded" height="80" width="80">
                  <v-icon dark large>mdi-login</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left plm--text mt-n4 fontFuturaMedium"
                >
                  Panel de adminstración
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-card-text class="pa-0 pt-3">
              <v-text-field
                :rules="emailRules"
                color="blue darken-2"
                outlined
                v-model="login.email_user"
                label="E-mail"
                placeholder="E-mail"
                required
              >
              </v-text-field>

              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                name="input-10-1"
                hint="Mínimo 8 caracteres"
                color="blue darken-2"
                label="Contraseña"
                v-model="login.pass_user"
                placeholder="Contraseña"
                outlined
                @keyup.enter="loginUser"
                @click:append="show = !show"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="pa-0 ma-0">
              <v-spacer></v-spacer>
              <v-btn
                @click.prevent="loginUser"
                class="ma-1"
                color="blue darken-4 white--text"
              >
                Ingresar
              </v-btn>
            </v-card-actions>
            <v-card-text class="d-flex mt-3 justify-center">
              <img class="logoFPOlogin" :src="logoFpo" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import swal from 'sweetalert';

export default {
  name: 'LoginComponent',
  data() {
    return {
      logoPlm: require('../assets/images/logoPLMslogan.svg'),
      logoFpo: require('../assets/images/logoFarmapreciosOnline.svg'),
      login: {
        email_user: '',
        pass_user: '',
      },
      emailRules: [
        (v) => !!v || 'Email requerido.',
        (v) => /.+@.+\..+/.test(v) || 'Email invalido',
      ],
      show: false,
      password: 'Password',
      rules: {
        required: (value) => !!value || 'Campo obligatorio',
        min: (v) => v.length >= 8 || 'Mínimo 8 caracteres',
        // emailMatch: () => (`The email and password you entered don't match`),
      },
    };
  },

  methods: {
    loginUser() {
      this.login.email_user = this.login.email_user.toLowerCase();
      this.axios({ url: 'usuario/login', method: 'post', data: this.login })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem(
              'full_name',
              `${response.data.name} ${response.data.last_name}`
            );
            localStorage.setItem('auth', true);
            swal({
              title: 'Acceso concedido!!',
              text: 'Bienvenido a Farmaprecios Online',
              icon: 'success',
              button: 'OK',
            });
          } else {
            swal({
              title: 'Acceso concedido!!',
              text: response.data['message'],
              icon: 'success',
              button: 'OK',
            });
          }
          this.$router.push({ name: 'Home' });
        })
        .catch((error) => {
          switch (error.response.status) {
            case 403:
              swal({
                title: 'Datos incorrectos',
                text: error.response.data['message'],
                icon: 'error',
              });
              break;
            case 404:
              swal({
                title: 'Datos incorrectos',
                text: error.response.data['message'],
                icon: 'error',
              });
              break;
            default:
              swal({
                title: 'Error',
                text: 'Error en el servidor',
                icon: 'error',
              });
              break;
          }
          if (error.response) {
            // console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error);
          return error;
        });
    },
  },
};
</script>

<style scoped>
.login {
  background-image: url('../assets/images/backgroundLogin.jpg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding: 0 !important;
  overflow-x: scroll;
}
.logoFPOlogin {
  /* height: 50px; */
  width: 90%;
  text-align: center;
  max-height: 64px;
}

.v-bar--underline {
  padding: 0 !important;
}

.cardLogin {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 20px -2px rgba(0, 82, 161, 0.5),
    0px 2px 2px 0px rgba(0, 82, 161, 0.5), 0px 1px 5px 0px rgba(0, 82, 161, 0.5);
}

.swal-title {
  font-family: 'FuturaStd-Book' !important;
}

.myFont {
  font-family: 'FuturaStd-Book';
}

/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  .logoFPOlogin {
    /* min-height: 40px; */
    width: 100%;
  }
}

/* iPhone 5/SE Landscape */
@media (max-width: 568px) and (min-width: 560px) {
}

/* iPhone 6/7/8 Landscape */
/* @media (max-width: 667px) and (min-width: 569px) { */
@media (min-width: 667px) and (orientation: landscape) {
  .cardLogin {
    margin-top: 150px !important;
  }
  /* .contentCardLogin{
    margin-top: 12rem;
  } */
}

@media (min-width: 640px) and (max-height: 360px) {
  .cardLogin {
    margin-top: 200px !important;
  }
  /* .contentCardLogin{
    margin-top: 12rem;
  } */
}

/* iPhone 6/7/8 Plus Landscape */
@media (min-width: 415px) and (orientation: landscape) {
  /* .contentCardLogin{
    margin-top: 12rem;
  } */
}

/* iPhone X/XS Landscape */
@media (max-width: 890px) and (min-width: 769px) {
  /* .contentCardLogin{
    margin-top: 12rem;
  }   */
}

/* iPhone X Landscape */
@media only screen and (min-width: 812px) and (orientation: landscape) {
  /* .contentCardLogin{
    margin-top: 12rem;
  }   */
}

/* iPhone 12 Pro Max Landscape */
@media only screen and (min-width: 926px) and (orientation: landscape) {
  /* .contentCardLogin{
    margin-top: 1rem;
  }   */
}

/* iPhone 12 Pro Max Portrait */
@media only screen and (min-width: 428px) and (orientation: portrait) {
  /* .contentCardLogin{
    margin-top: 12rem;
  }   */
}

/* iPad portrait */
@media (min-width: 768px) and (orientation: portrait) {
  .cardLogin {
    margin-top: 0 !important;
  }
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .cardLogin {
    margin-top: 0 !important;
  }
}

/* iPad Pro portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (min-device-width: 938px) and (max-device-width: 938px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 602px) and (max-device-width: 602px) and (orientation: portrait) {
}

/* Tab's Landscape */
@media (max-width: 1366px) and (min-width: 992px) and (orientation: landscape) {
  .cardLogin {
    margin-top: 0 !important;
  }
}
</style>
