<template>
  <div>
    <v-container>
      <v-layout>
        <v-row xs12 sm12 md6 lg12 class="pt-10">
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card class="ma-3 mt-5 pt-5">

              <v-list-item>
                <v-list-item-avatar height="80" width="80" elevation="10" tile class="mt-n15">
                  <v-sheet color="blue darken-4" rounded height="80" width="80">
                    <v-icon dark large>mdi-account-cog</v-icon>
                  </v-sheet>
                </v-list-item-avatar>

                <v-list-item-content>
                  <div class="headline text-left secondary--text mt-n4 fontFuturaMedium">
                    USUARIOS</div>
                </v-list-item-content>

              </v-list-item>

              <v-card-text>
                <div class="fontFuturaBook text-h6">
                  Crear, habilitar, conceder permisos.</div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn to="/users" class="ma-3 " color="success">
                  Administrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>


          <v-col cols="12" sm="12" md="6" lg="4">
            
            <v-card class="ma-3 mt-5 pt-5">

              <v-list-item>
                <v-list-item-avatar height="80" width="80" elevation="10" tile class="mt-n15">
                  <v-sheet color="cyan darken-1" rounded height="80" width="80">
                    <v-icon dark large>mdi-key</v-icon>
                  </v-sheet>
                </v-list-item-avatar>

                <v-list-item-content>
                  <div class="headline text-left secondary--text mt-n4 fontFuturaMedium">
                    LICENCIAS</div>
                </v-list-item-content>

              </v-list-item>

              <v-card-text>
                <div class="fontFuturaBook text-h6">
                  Crear, parametrizar vigencia y cantidad.</div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn to="/licenses" class="ma-3 " color="success">
                  Administrar
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-col>


          <v-col cols="12" sm="12" md="6" lg="4">

            <v-card class="ma-3 mt-5 pt-5">

              <v-list-item>
                <v-list-item-avatar height="80" width="80" elevation="10" tile class="mt-n15">
                  <v-sheet color="light-blue" rounded height="80" width="80">
                    <v-icon dark large>fact_check</v-icon>
                  </v-sheet>
                </v-list-item-avatar>

                <v-list-item-content>
                  <div class="headline text-left secondary--text mt-n4 fontFuturaMedium">
                    PRODUCTOS</div>
                </v-list-item-content>

              </v-list-item>

              <v-card-text>
                <div class="fontFuturaBook text-h6">
                  Crear, parametrizar vigencia y cantidad.</div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn to="/products" class="ma-3 " color="success">
                  Administrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template> 

<script>
import '/src/assets/fonts/font.css';
// @ is an alias to /src
// import NavigationBar from '@/components/NavigationBar.vue';

export default {
  name: 'Home',
  components: {
    // NavigationBar,
  }
}
</script>


<style scoped>

</style>