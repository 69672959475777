<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet color="cyan darken-1" rounded height="80" width="80">
                  <v-icon dark large>checklist</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  INSTRUCCIONES DE USO
                </div>
              </v-list-item-content>
            </v-list-item>

            <template>
              <v-container>
                <v-layout>
                  <v-row class="justify-center py-10">
                    <v-col cols="12" sm="12" md="6" lg="4">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto"
                          color="light lighten-4"
                          max-width="450">
                          <v-img
                            :aspect-ratio="16/9"
                            src="@/assets/images/BkgUsuarios.jpg">
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text"
                                style="height: 100%;"
                              >
                                Ver tutorial
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-card-text
                            class="pt-6"
                            style="position: relative;">
                          <v-dialog
                            max-width="500"
                            v-model="dialog">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              absolute
                              color="red darken-4"
                              class="white--text"
                              fab
                              large
                              right
                              top
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-video</v-icon>
                            </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar color="primary" dark
                                  ><h4>GESTIONAR USUARIOS</h4></v-toolbar
                                >
                                <v-card-text class="pa-0">
                                  <div class="pa-3 d-flex justify-center">
                                    <video-embed  class="embed-responsive-16by9"  src="https://youtu.be/ry0Fk9jdi1w"></video-embed>
                                    <!-- <video width="100%" controls src="@/assets/media/FPO_Gestionar_Usuarios.mp4" type="video/mp4"></video> -->
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn @click="dialog.value = false"
                                  color="red darken-4"
                                  class="white--text"
                                    >CERRAR</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                            <div class="primary--text title mb-2">
                              GESTIONAR USUARIOS
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>


                    <v-col cols="12" sm="12" md="6" lg="4">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto"
                          color="light lighten-4"
                          max-width="450">
                          <v-img
                            :aspect-ratio="16/9"
                            src="@/assets/images/BkgLicencias.jpg">
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text"
                                style="height: 100%;"
                              >
                                Ver tutorial
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-card-text
                            class="pt-6"
                            style="position: relative;">
                          <v-dialog
                            max-width="500"
                            v-model="dialog1">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              absolute
                              color="red darken-4"
                              class="white--text"
                              fab
                              large
                              right
                              top
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-video</v-icon>
                            </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar color="primary" dark
                                  ><h4>GESTIONAR LICENCIAS</h4></v-toolbar
                                >
                                <v-card-text class="pa-0">
                                  <div class="pa-3 d-flex justify-center">
                                    <video-embed src="https://youtu.be/p5_WhidAahw"></video-embed> 
                                    <!-- <video width="100%" controls src="@/assets/media/FPO_Gestionar_Licencias.mp4" type="video/mp4"></video> -->
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn @click="dialog.value = false"
                                  color="red darken-4"
                                  class="white--text"
                                    >CERRAR</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                            <div class="primary--text title mb-2">
                              GESTIONAR LICENCIAS
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>
                    
                    
                    <v-col cols="12" sm="12" md="6" lg="4">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto"
                          color="light lighten-4"
                          max-width="450">
                          <v-img
                            :aspect-ratio="16/9"
                            src="@/assets/images/BkgProductos.jpg">
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text"
                                style="height: 100%;">
                                Ver tutorial
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-card-text
                            class="pt-6"
                            style="position: relative;">

                          <v-dialog
                            max-width="500"
                            v-model="dialog2">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              absolute
                              color="red darken-4"
                              class="white--text"
                              fab
                              large
                              right
                              top
                              v-bind="attrs"
                              v-on="on">

                              <v-icon>mdi-video</v-icon>
                            </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar color="primary" dark
                                  ><h4>GESTIONAR PRODUCTOS</h4></v-toolbar>
                                <v-card-text class="pa-0">
                                  <div class="pa-3 d-flex justify-center">
                                    <video-embed src="https://youtu.be/pgJqmH1l4FE"></video-embed>
                                    <!-- <video width="100%" controls src="@/assets/media/FPO_Gestionar_Productos.mp4" type="video/mp4"></video> -->
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn @click="dialog.value = false"
                                  color="red darken-4"
                                  class="white--text"
                                    >CERRAR</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                            <div class="primary--text title mb-2">
                              GESTIONAR PRODUCTOS
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>
                    
                    <v-col cols="12" sm="12" md="6" lg="4">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto"
                          color="light lighten-4"
                          max-width="450">
                          <v-img
                            :aspect-ratio="16/9"
                            src="@/assets/images/BkgClientes.jpg">
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text"
                                style="height: 100%;">
                                Ver tutorial
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-card-text
                            class="pt-6"
                            style="position: relative;">

                          <v-dialog
                            max-width="500"
                            v-model="dialog3">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              absolute
                              color="red darken-4"
                              class="white--text"
                              fab
                              large
                              right
                              top
                              v-bind="attrs"
                              v-on="on">

                              <v-icon>mdi-video</v-icon>
                            </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar color="primary" dark
                                  ><h4>GESTIONAR CLIENTES</h4></v-toolbar>
                                <v-card-text class="pa-0">
                                  <div class="pa-3 d-flex justify-center">
                                    <video-embed src="https://youtu.be/-EhwplgLv6c"></video-embed> 
                                    <!-- <video width="100%" controls src="@/assets/media/FPO_Gestionar_Clientes.mp4" type="video/mp4"></video> -->
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn @click="dialog.value = false"
                                  color="red darken-4"
                                  class="white--text"
                                    >CERRAR</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                            <div class="primary--text title mb-2">
                              GESTIONAR CLIENTES
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>
                    
                    <v-col cols="12" sm="12" md="6" lg="4">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          class="mx-auto"
                          color="light lighten-4"
                          max-width="450">
                          <v-img
                            :aspect-ratio="16/9"
                            src="@/assets/images/BkgReporte.jpg">
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text"
                                style="height: 100%;">
                                Ver tutorial
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-card-text
                            class="pt-6"
                            style="position: relative;">

                          <v-dialog
                            max-width="500"
                            v-model="dialog4">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              absolute
                              color="red darken-4"
                              class="white--text"
                              fab
                              large
                              right
                              top
                              v-bind="attrs"
                              v-on="on">

                              <v-icon>mdi-video</v-icon>
                            </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar color="primary" dark
                                  ><h4>GENERAR REPORTE</h4></v-toolbar>
                                <v-card-text class="pa-0">
                                  <div class="pa-3 d-flex justify-center">
                                    <video-embed src="https://youtu.be/7_Ap59iaEu0"></video-embed> 
                                    <!-- <video width="100%" controls src="@/assets/media/FPO_Generar_Reporte.mp4" type="video/mp4"></video> -->
                                  </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn @click="dialog.value = false"
                                  color="red darken-4"
                                  class="white--text"
                                    >CERRAR</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>

                            <div class="primary--text title mb-2">
                              GENERAR REPORTE
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>


                  </v-row>
                </v-layout>
              </v-container>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  
  data() {
    return {
      dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
    };
  },

  computed: {},

  watch: {},

  methods: {},
};
</script>

<style scoped>


.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}


/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
/* .v-data-table__mobile-row__header{
	color: red !important;
} */
</style>
