<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet color="cyan darken-1" rounded height="80" width="80">
                  <v-icon dark large>mdi-shield-lock-outline</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  AUDITORÍA
                </div>
              </v-list-item-content>
            </v-list-item>

            <template>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="12" lg="5">
                    <!-- v-model="auditar" -->
                    <v-select
                      :items="items"
                      label="SELECCIONAR TABLA A REVISAR"
                      item-text="table_select"
                      dense
                      outlined
                      item-value="index"
                      return-object
                      v-on:change="cambiarAuditoria"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template>
              <v-container>
                <v-layout>
                  <v-row>
                    <v-col>
                      <div class="px-sm-1 px-md-5">
                        <!-- <v-timeline :reverse="reverse" dense> -->
                        <v-timeline dense>
                          <v-timeline-item
                            v-for="item in dataAudit"
                            :key="item"
                          >
                            <v-card class="elevation-2">
                              <v-card-text>
                                <span class="primary--text">Fecha: </span
                                >{{ item.fecha }}
                                <span class="primary--text ml-5">Hora: </span>
                                {{ item.hora }}
                              </v-card-text>

                              <v-card-text>
                                <span class="primary--text">Usuario: </span
                                >{{ item.user_name }}
                                <span class="primary--text ml-5"
                                  >Acción ejecudata: </span
                                ><b>{{ item.accion }}</b>
                              </v-card-text>
                              <v-card-text>
                                <span class="primary--text"
                                  >OLD DATA: <br /> </span
                                >{{ item.old_row_data }}
                              </v-card-text>
                              <v-card-text>
                                <span class="primary--text"
                                  >NEW DATA: <br /> </span
                                >{{ item.new_row_data }}
                              </v-card-text>
                            </v-card>
                          </v-timeline-item>
                        </v-timeline>
                      </div>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-container>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { table_select: 'USUARIOS', index: 0 },
        { table_select: 'LICENCIAS', index: 1 },
        { table_select: 'PRODUCTOS', index: 2 },
        { table_select: 'LABORATORIOS', index: 3 },
        { table_select: 'SUSTANCIAS', index: 4 },
        { table_select: 'FORMA FARMACÉUTICA', index: 5 },
        { table_select: 'CLIENTES', index: 6 },
      ],
      dataAudit: [],
    };
  },

  computed: {},

  watch: {},

  methods: {
    cambiarAuditoria(a) {
      switch (a.index) {
        case 0:
          this.axios({ url: 'auditoria/listAuditUsers' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;
        case 1:
          this.axios({ url: 'auditoria/listAuditCodes' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;
        case 2:
          this.axios({ url: 'auditoria/listAuditPrices' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;
        case 3:
          this.axios({ url: 'auditoria/listAuditLabs' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;
        case 4:
          this.axios({ url: 'auditoria/listAuditSubstances' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;
        case 5:
          this.axios({ url: 'auditoria/listAuditForms' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;
        case 6:
          this.axios({ url: 'auditoria/listAuditClients' })
            .then((response) => {
              this.dataAudit = response.data;
              this.dataAudit.forEach((item) => {
                item['active'] = item['active'] === '1' ? true : false;
              });
            })
            .catch((error) => {
              if (error.response.status === 403) {
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
              }
              this.dataAudit = [];
            });
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
/* .v-data-table__mobile-row__header{
	color: red !important;
} */
</style>
