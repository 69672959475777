<template>
  <v-card>
    <v-toolbar flat dark color="red darken-4">
      <v-toolbar-title>
        <strong>Códigos por expirar</strong>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        class="mx-2 mt-0 mr-0"
        fab
        dark
        small
        color="red ligthen-4 elevation-4 d-flex"
        @click="closeModal"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="px-3">
      <v-timeline align-top dense>
        <v-timeline-item
          color="red darken-4"
          small
          v-for="(values, moroso) in morosos"
          :key="moroso"
        >
          <div>
            <div class="font-weight-normal">
              <strong>{{ moroso }}</strong>
            </div>
            <div
              v-for="(code, index) in values.codes"
              :key="index"
              class="ml-6"
            >
              <strong> {{ code }}</strong> - Expira en
              <strong>{{ values.days[index] }}</strong> días
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="" color="success" @click="closeModal">
        Entendido
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      morosos: [],
    };
  },
  created() {
    this.listExpiringCodes();
  },
  methods: {
    listExpiringCodes() {
      this.axios({ url: 'codigo/listDatesCodes' })
        .then((response) => {
          this.morosos = response.data;
          console.log('mororoso???', this.morosos);
        })
        .catch((error) => {
          this.cargando = false;
          if (error.response.status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('full_name');
            this.$router.push({ name: 'Login' });
          }
        });
    },
    closeModal() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
/* ol {
  counter-reset: item;
}
li {
  display: block;
}
li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
} */
</style>
