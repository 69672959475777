<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet color="cyan darken-1" rounded height="80" width="80">
                  <v-icon dark large>mdi-key</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  LICENCIAS
                </div>
              </v-list-item-content>
            </v-list-item>

            <template>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="12" lg="4">
                    <v-select
                      v-model="client"
                      :items="clients"
                      label="SELECCIONAR CLIENTE"
                      item-text="company_name"
                      dense
                      outlined
                      return-object
                      v-on:change="addCodes"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <v-data-table
              :headers="headers"
              :items="codes"
              class="elevation-1"
              :items-per-page="5"
              :search="search"
              align="center"
            >
              <template v-slot:[`item.actions`]="{ item }">
                {{ item.actions }}
                <v-btn icon medium color="success" @click="editItem(item)"
                  ><v-icon>mdi-lead-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!isConfigDisable"
                      >
                        Configurar nueva
                        <v-icon class="medium ml-3">mdi-key</v-icon>
                      </v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-text-search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mr-5"
                      ></v-text-field>
                    </template>
                    <v-form ref="form" v-model="isFormValid">
                      <v-card>
                        <v-card-title>
                          <span class="headline primary--text">{{
                            formTitle
                          }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <!-- :return-value.sync="date" -->
                                <v-menu
                                  ref="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="createdAt"
                                      label="Fecha de Inicio"
                                      prepend-icon="mdi-calendar-check"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      disabled
                                    ></v-text-field>
                                  </template>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <!-- v-model="menu" -->
                                <!-- :return-value.sync="date" -->
                                <v-menu
                                  ref="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.ended_at"
                                      :rules="[
                                        (v) => !!v || 'Campo obligatorio',
                                      ]"
                                      label="Fecha de finalizacion"
                                      prepend-icon="mdi-calendar-edit"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>

                                  <v-date-picker
                                    v-model="editedItem.ended_at"
                                    no-title
                                    scrollable
                                    locale="es"
                                    :allowed-dates="minDate"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save()"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.code"
                                  label="Código"
                                  disabled
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.code_status"
                                  label="Estado"
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-card
                                  v-if="editedIndex === -1"
                                  class="mx-auto"
                                  outlined
                                >
                                  <div class="overline pa-3 primary--text">
                                    Cantidad de licencias
                                    <strong>{{ counter }}</strong>
                                  </div>
                                  <v-container fluid pt-0>
                                    <div class="text-center">
                                      <v-btn
                                        class="mx-2 elevation-3"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        v-on:click="increase"
                                      >
                                        <v-icon dark> mdi-plus </v-icon>
                                      </v-btn>

                                      <v-btn
                                        class="mx-2 elevation-3"
                                        fab
                                        dark
                                        small
                                        color="primary"
                                        v-on:click="decrease"
                                      >
                                        <v-icon dark> mdi-minus </v-icon>
                                      </v-btn>
                                    </div>
                                  </v-container>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red darken-4" dark @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save"
                            :disabled="!isFormValid"
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:no-data>
                <v-alert
                  :value="true"
                  outlined
                  text
                  color="success"
                  icon="mdi-checkbox-marked-outline"
                  border="left"
                  class="ma-5"
                >
                  <!-- Por favor seleccione un cliente -->
                  POR FAVOR SELECCIONE UN CLINETE
                </v-alert>
                <v-btn color="primary" @click="list" class="mb-5">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      createdAt: '',
      search: '',
      isFormValid: false,
      isConfigDisable: false,
      select: [],
      clients: [],
      client: {},
      counter: 1,

      cargando: true,
      radios: null,
      dialog: false,
      headers: [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'code',
        },
        {
          text: 'Fecha de inicio',
          align: 'start',
          value: 'createdAt',
          sortable: false,
        },
        {
          text: 'Fecha de finalización',
          align: 'start',
          value: 'ended_at',
        },
        {
          text: 'Estado',
          align: 'start',
          value: 'code_status',
          sortable: false,
        },
        {
          text: 'Acción',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],

      codes: [],

      editedIndex: -1,
      editedItem: {
        code: '',
        createdAt: '',
        ended_at: '',
        code_status: '',
        actions: '',
        counter: '',
        client_email: '',
        client_id: '',
      },
      defaultItem: {
        code: '',
        createdAt: '',
        ended_at: '',
        code_status: '',
        actions: '',
        counter: '',
        client_email: '',
        client_id: '',
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'CONFIGURAR NUEVA LICENCIA'
        : 'EDITAR LICENCIA';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.createdAt = new Date().toLocaleString('en-CA', {
      timeZone: 'America/Bogota',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    this.clientes();
  },

  methods: {
    clientes() {
      this.axios({ url: 'cliente/listClients' })
        .then((response) => {
          this.clients = response.data;
          this.client = {};
        })
        .catch((error) => {
          this.cargando = false;
          if (error.response.status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('full_name');
            this.$router.push({ name: 'Login' });
          }
        });
    },

    addCodes(a) {
      this.isConfigDisable = true;
      this.codes = a.Codes;
    },

    minDate: (val) => Date.parse(val) > Date.now() - 1.8e7,

    list() {
      this.axios({
        url: 'codigo/listCodes',
        params: { client_id: this.client.client_id },
      })
        .then((response) => {
          this.codes = response.data;
          this.client.Codes = response.data;
          this.cargando = false;
        })
        .catch((error) => {
          this.cargando = false;
          if (error.response.status === 403) {
            localStorage.removeItem('auth');
            localStorage.removeItem('full_name');
            this.$router.push({ name: 'Login' });
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.codes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.createdAt = item.createdAt;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.createdAt = new Date().toLocaleString('en-CA', {
        timeZone: 'America/Bogota',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },

    save() {
      this.editedItem.user_name = localStorage.getItem('full_name');
      this.editedItem.counter = this.counter;
      this.editedItem.client_email = this.client.email;
      this.editedItem.client_id = this.client.client_id;
      this.editedItem.client_code = this.client.contact_name;
      if (this.editedIndex === -1) {
        this.axios({
          url: 'codigo/addCode',
          method: 'post',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Ha creado una licencia!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
            }
          });
      } else {
        this.axios({
          url: 'codigo/editCodeEndDate',
          method: 'put',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Ha editado una licencia!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
            }
          });
      }
      this.close();
    },

    increase: function() {
      this.counter++;
    },
    decrease: function() {
      if (this.counter > 1) {
        this.counter--;
      }
    },
  },
};
</script>

<style scoped>
/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
/* .v-data-table__mobile-row__header{
	color: red !important;
} */
</style>
