<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet color="blue darken-4" rounded height="80" width="80">
                  <v-icon dark large>mdi-account-cog</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  USUARIOS
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="users"
              :items-per-page="5"
              :loading="cargando"
              :search="search"
              loading-text="Cargando... Por favor espere"
              align="center"
            >
              <!-- mobile-breakpoint="1025" -->
              <template v-slot:[`item.active`]="{ item }">
                <v-simple-checkbox
                  color="blue darken-4"
                  v-model="item.active"
                  disabled
                ></v-simple-checkbox>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                {{ item.actions }}
                <v-btn icon medium color="success" @click="editItem(item)"
                  ><v-icon>mdi-lead-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                  No hay registros por mostrar
                </v-alert>
                <v-btn color="primary" @click="list"> Reset </v-btn>
              </template>

              <!-- INICIO - MODAL -->
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Crear nuevo
                        <v-icon class="medium ml-3">mdi-account-plus</v-icon>
                      </v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-text-search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mr-5"
                      ></v-text-field>
                    </template>
                    <v-form ref="form" v-model="isFormValid">
                      <v-card>
                        <v-card-title>
                          <span class="headline primary--text">{{
                            formTitle
                          }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.name_user"
                                  label="Nombre"
                                  :rules="nameRules"
                                  counter
                                  maxlength="50"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.lastname_user"
                                  label="Apellido(s)"
                                  :rules="lastNameRules"
                                  counter
                                  maxlength="50"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.ni_user"
                                  label="Identificación"
                                  :rules="identificationRules"
                                  counter
                                  maxlength="15"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.email_user"
                                  label="Email"
                                  :rules="emailRules"
                                  counter
                                  maxlength="50"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  color="blue darken-4"
                                  v-model="editedItem.pass_user"
                                  label="Contraseña"
                                  hint="Mínimo 7 caracteres"
                                  :rules="passRules"
                                  v-if="editedIndex === -1"
                                ></v-text-field>
                                <v-text-field
                                  color="blue darken-4"
                                  v-model="editedItem.pass_user"
                                  label="Contraseña"
                                  hint="Mínimo 7 caracteres"
                                  v-if="editedIndex !== -1"
                                ></v-text-field>
                                <v-progress-linear
                                  :background-opacity="opacity"
                                  :color="score.color"
                                  :value="score.value"
                                ></v-progress-linear>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <template>
                                  <v-checkbox
                                    color="blue darken-4"
                                    v-model="editedItem.active_user"
                                    label="Activo"
                                  ></v-checkbox>
                                </template>
                              </v-col>
                            </v-row>
                          </v-container>

                          <v-card class="mx-auto" outlined>
                            <div class="overline pa-3 primary--text">
                              <strong>Permisos</strong>
                            </div>
                            <v-container fluid pt-0>
                              <v-radio-group class="mt-0" v-model="role_user">
                                <template v-slot:label>
                                  <div class="pb-3">
                                    Seleccione el
                                    <strong class="primary--text">ROL</strong>
                                    para este usuario
                                  </div>
                                </template>
                                <v-radio value="Administrador">
                                  <template v-slot:label>
                                    <v-tooltip bottom color="primary">
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <div v-bind="attrs" v-on="on">
                                          Administrador
                                        </div>
                                      </template>
                                      <span
                                        >PERMISOS:<br />Consultar, Editar
                                        productos, Reportes, Licencias</span
                                      >
                                    </v-tooltip>
                                  </template>
                                </v-radio>
                                <v-radio value="Vendedor">
                                  <template v-slot:label>
                                    <v-tooltip bottom color="primary">
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <div v-bind="attrs" v-on="on">
                                          Vendedor
                                        </div>
                                      </template>
                                      <span
                                        >PERMISOS:<br />Consultar, Generar
                                        reportes</span
                                      >
                                    </v-tooltip>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </v-container>
                          </v-card>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red darken-4" dark @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn
                            :disabled="!isFormValid"
                            color="success"
                            @click="save"
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import zxcvbn from 'zxcvbn';
export default {
  data() {
    return {
      isFormValid: false,
      search: '',
      cargando: true,
      role_user: 'Vendedor',
      dialog: false,
      nameRules: [
        (v) => !!v || 'Nombre requerido',
        (v) => v.length <= 50 || 'El nombre no debe superar los 50 caracteres',
      ],
      lastNameRules: [
        (v) => !!v || 'Apellido(s) requerido',
        (v) =>
          v.length <= 50 || 'El apellido no debe superar los 50 caracteres',
      ],
      identificationRules: [
        (v) => !!v || 'No. de indentificación requerido',
        (v) =>
          v.length <= 50 ||
          'El N° de identificación no debe superar los 15 caracteres',
      ],
      emailRules: [
        (v) => !!v || 'El Email es obligatorio',
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Email invalido',
      ],
      passRules: [
        (v) => v.length >= 7 || 'Mínimo 7 caracteres',
        (v) =>
          zxcvbn(v).score >= 3 ||
          'Por favor escoja una contraseña mas robusta. Intente una mezcla de letras, números, y simbolos.',
      ],
      headers: [
        {
          text: 'Identificación',
          align: 'start',
          sortable: false,
          value: 'num_identification',
        },
        {
          text: 'Nombre',
          align: 'start',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Apellidos',
          align: 'start',
          value: 'last_name',
          sortable: false,
        },
        {
          text: 'Email',
          align: 'start',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Fecha de creación',
          align: 'start',
          value: 'createdAt',
          sortable: false,
        },
        {
          text: 'Activo',
          align: 'center',
          value: 'active',
          sortable: false,
        },
        {
          text: 'Acción',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],

      users: [],

      editedIndex: -1,
      editedItem: {
        ni_user: '',
        name_user: '',
        lastname_user: '',
        email_user: '',
        pass_user: '',
        active_user: '',
        role_user: '',
        user_name: '',
        user_id: '',
      },
      defaultItem: {
        ni_user: '',
        name_user: '',
        lastname_user: '',
        email_user: '',
        pass_user: '',
        active_user: '',
        role_user: '',
        user_name: '',
        user_id: '',
      },

      user: {},
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'CREAR NUEVO USUARIO' : 'EDITAR USUARIO';
    },
    // isDisabled() {
    //   console.log('ni???', this.rules.required);
    //   return true;
    // },
    score() {
      const result = zxcvbn(this.editedItem.pass_user);
      switch (result.score) {
        case 4:
          return {
            color: 'light-blue',
            value: 100,
          };
        case 3:
          return {
            color: 'light-green',
            value: 75,
          };
        case 2:
          return {
            color: 'yellow',
            value: 50,
          };
        case 1:
          return {
            color: 'orange',
            value: 25,
          };
        default:
          return {
            color: 'red',
            value: 0,
          };
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.list();
  },

  methods: {
    list() {
      this.axios({ url: 'usuario/listUsers' })
        .then((response) => {
          this.users = response.data;
          this.users.forEach((item) => {
            item['active'] = item['active'] === '1' ? true : false;
          });
          this.cargando = false;
        })
        .catch((error) => {
          switch (error.response.status) {
            case 403:
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
              break;
            default:
              swal({
                title: 'Error',
                text: 'Error en el servidor',
                icon: 'error',
              });
              break;
          }
          this.cargando = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem.ni_user = item.num_identification;
      this.editedItem.name_user = item.name;
      this.editedItem.lastname_user = item.last_name;
      this.editedItem.email_user = item.email;
      this.editedItem.active_user = item.active;
      this.role_user = this.editedIndex === -1 ? this.role_user : item.role;
      this.editedItem.user_id = item.user_id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.role_user = 'Vendedor';
      });
    },

    save() {
      this.editedItem.user_name = localStorage.getItem('full_name');
      this.editedItem.role_user = this.role_user;
      if (this.editedIndex > -1) {
        this.axios({
          url: 'usuario/editUser',
          method: 'put',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Ha editado un usuario!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      } else {
        this.axios({
          url: 'usuario/signup',
          method: 'post',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Nuevo usuario creado!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      }
      this.close();
    },
  },
};
</script>

<style scoped>
/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
</style>
