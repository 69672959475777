var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-row',{staticClass:"pt-10",attrs:{"xs12":"","sm12":"","md6":"","lg12":""}},[_c('v-col',{staticClass:"contentUsurios",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"ma-3 mt-5 pt-5"},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mt-n15",attrs:{"height":"80","width":"80","elevation":"10","tile":""}},[_c('v-sheet',{attrs:{"color":"cyan darken-1","rounded":"","height":"80","width":"80"}},[_c('v-icon',{attrs:{"dark":"","large":""}},[_vm._v("checklist")])],1)],1),_c('v-list-item-content',[_c('div',{staticClass:"headline text-left secondary--text mt-n4 fontFuturaMedium"},[_vm._v(" INSTRUCCIONES DE USO ")])])],1),[_c('v-container',[_c('v-layout',[_c('v-row',{staticClass:"justify-center py-10"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"light lighten-4","max-width":"450"}},[_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("@/assets/images/BkgUsuarios.jpg")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text",staticStyle:{"height":"100%"}},[_vm._v(" Ver tutorial ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"absolute":"","color":"red darken-4","fab":"","large":"","right":"","top":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h4',[_vm._v("GESTIONAR USUARIOS")])]),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-3 d-flex justify-center"},[_c('video-embed',{staticClass:"embed-responsive-16by9",attrs:{"src":"https://youtu.be/ry0Fk9jdi1w"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red darken-4"},on:{"click":function($event){dialog.value = false}}},[_vm._v("CERRAR")])],1)],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('div',{staticClass:"primary--text title mb-2"},[_vm._v(" GESTIONAR USUARIOS ")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"light lighten-4","max-width":"450"}},[_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("@/assets/images/BkgLicencias.jpg")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text",staticStyle:{"height":"100%"}},[_vm._v(" Ver tutorial ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"absolute":"","color":"red darken-4","fab":"","large":"","right":"","top":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h4',[_vm._v("GESTIONAR LICENCIAS")])]),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-3 d-flex justify-center"},[_c('video-embed',{attrs:{"src":"https://youtu.be/p5_WhidAahw"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red darken-4"},on:{"click":function($event){dialog.value = false}}},[_vm._v("CERRAR")])],1)],1)]}}],null,true),model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}}),_c('div',{staticClass:"primary--text title mb-2"},[_vm._v(" GESTIONAR LICENCIAS ")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"light lighten-4","max-width":"450"}},[_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("@/assets/images/BkgProductos.jpg")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text",staticStyle:{"height":"100%"}},[_vm._v(" Ver tutorial ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"absolute":"","color":"red darken-4","fab":"","large":"","right":"","top":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h4',[_vm._v("GESTIONAR PRODUCTOS")])]),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-3 d-flex justify-center"},[_c('video-embed',{attrs:{"src":"https://youtu.be/pgJqmH1l4FE"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red darken-4"},on:{"click":function($event){dialog.value = false}}},[_vm._v("CERRAR")])],1)],1)]}}],null,true),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}}),_c('div',{staticClass:"primary--text title mb-2"},[_vm._v(" GESTIONAR PRODUCTOS ")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"light lighten-4","max-width":"450"}},[_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("@/assets/images/BkgClientes.jpg")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text",staticStyle:{"height":"100%"}},[_vm._v(" Ver tutorial ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"absolute":"","color":"red darken-4","fab":"","large":"","right":"","top":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h4',[_vm._v("GESTIONAR CLIENTES")])]),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-3 d-flex justify-center"},[_c('video-embed',{attrs:{"src":"https://youtu.be/-EhwplgLv6c"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red darken-4"},on:{"click":function($event){dialog.value = false}}},[_vm._v("CERRAR")])],1)],1)]}}],null,true),model:{value:(_vm.dialog3),callback:function ($$v) {_vm.dialog3=$$v},expression:"dialog3"}}),_c('div',{staticClass:"primary--text title mb-2"},[_vm._v(" GESTIONAR CLIENTES ")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":"light lighten-4","max-width":"450"}},[_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("@/assets/images/BkgReporte.jpg")}},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-1 white--text",staticStyle:{"height":"100%"}},[_vm._v(" Ver tutorial ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"pt-6",staticStyle:{"position":"relative"}},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"absolute":"","color":"red darken-4","fab":"","large":"","right":"","top":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-video")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('h4',[_vm._v("GENERAR REPORTE")])]),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"pa-3 d-flex justify-center"},[_c('video-embed',{attrs:{"src":"https://youtu.be/7_Ap59iaEu0"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"red darken-4"},on:{"click":function($event){dialog.value = false}}},[_vm._v("CERRAR")])],1)],1)]}}],null,true),model:{value:(_vm.dialog4),callback:function ($$v) {_vm.dialog4=$$v},expression:"dialog4"}}),_c('div',{staticClass:"primary--text title mb-2"},[_vm._v(" GENERAR REPORTE ")])],1)],1)]}}])})],1)],1)],1)],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }