<template>
  <div>
    <users-component></users-component>
  </div>
</template> 

<script>
import UsersComponent from '../components/UsersComponent';
export default {
  components: {
    UsersComponent,
  }
}
</script>