<template>
  <div>
    <products-component></products-component>
  </div>
</template> 

<script>
import ProductsComponent from '../components/ProductsComponent.vue';
export default {
  components: {
    ProductsComponent,
  }
}
</script>



