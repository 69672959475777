<template>
  <div>
    <licenses-component></licenses-component>
  </div>
</template> 

<script>
import LicensesComponent from '../components/LicensesComponent';
export default {
  components: {
    LicensesComponent,
  }
}
</script>