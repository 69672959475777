<template>
  <div>
    <clients-component></clients-component>
  </div>
</template> 

<script>
import ClientsComponent from '../components/ClientsComponent.vue'
export default {
  components: {
    ClientsComponent
  }
}
</script>
