<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" lg="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet
                  color="light-blue darken-1"
                  rounded
                  height="80"
                  width="80"
                >
                  <v-icon dark large>fact_check</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  PRODUCTOS
                </div>
                <div
                  class="headline text-left secondary--text mt-n1 fontFuturaMedium"
                >
                  <p class="primary--text body-2">
                    Última actualización {{ mes }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="products"
              :items-per-page="5"
              :loading="cargando"
              :search="search"
              loading-text="Cargando... Por favor espere"
              align="center"
              width="70%"
            >
              <!-- mobile-breakpoint="1025" -->
              <template v-slot:[`item.active`]="{ item }">
                <!-- {{ item.active }} -->
                <v-simple-checkbox
                  color="blue darken-4"
                  v-model="item.active"
                  disabled
                ></v-simple-checkbox>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                {{ item.actions }}
                <v-btn icon medium color="success" @click="editItem(item)"
                  ><v-icon>mdi-lead-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                  No hay registros por mostrar
                </v-alert>
                <v-btn color="primary" @click="list"> Reset </v-btn>
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <form
                        id="uploadForm"
                        enctype="multipart/form-data"
                        v-on:change="uploadFile"
                      >
                        <input id="fileUpload" type="file" hidden />
                        <v-btn
                          color="success"
                          dark
                          class="mb-2"
                          sm-12
                          md-6
                          @click="cargarArchivo"
                        >
                          Cargar productos
                          <v-icon class="medium ml-3">upload_file</v-icon>
                        </v-btn>
                      </form>

                      <v-btn
                        color="success"
                        dark
                        class="mb-2 mr-6"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Ingresar nuevo
                        <v-icon class="medium ml-3">fact_check</v-icon>
                      </v-btn>

                      <v-text-field
                        v-model="search"
                        append-icon="mdi-text-search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mr-5"
                      ></v-text-field>
                    </template>
                    <v-form ref="form" v-model="isFormValid">
                      <v-card>
                        <v-card-title>
                          <span class="headline primary--text">{{
                            formTitle
                          }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="lab"
                                  :items="labs"
                                  label="Laboratorio"
                                  item-value="division_id"
                                  item-text="name"
                                  dense
                                  outlined
                                  return-object
                                  :rules="requiredSelect"
                                ></v-select>
                                <!-- v-on:change="changeLab" -->
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.name_price"
                                  label="Producto"
                                  :rules="requiredRule"
                                  class="text-uppercase"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="sub"
                                  :items="subs"
                                  label="Sustancia"
                                  item-text="name"
                                  item-value="substance_id"
                                  dense
                                  outlined
                                  return-object
                                  :rules="requiredSelect"
                                ></v-select>
                                <!-- v-on:change="changeLab" -->
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="form"
                                  :items="forms"
                                  label="Forma Farmacéutica"
                                  item-text="name"
                                  item-value="form_id"
                                  dense
                                  outlined
                                  return-object
                                  :rules="requiredSelect"
                                ></v-select>
                                <!-- v-on:change="changeLab" -->
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  color="blue darken-4"
                                  v-model="editedItem.present_price"
                                  label="Presentación"
                                  :rules="requiredRule"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  color="blue darken-4"
                                  v-model="editedItem.price_price"
                                  :rules="priceRules"
                                  label="Precio"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  color="blue darken-4"
                                  v-model="editedItem.cum_price"
                                  label="CUM"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  color="blue darken-4"
                                  v-model="editedItem.atc_price"
                                  label="ATC"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <template>
                                  <v-checkbox
                                    color="blue darken-4"
                                    v-model="editedItem.active_price"
                                    label="Activo"
                                  ></v-checkbox>
                                </template>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red darken-4" dark @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save"
                            :disabled="!isFormValid"
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isFormValid: false,
      search: '',
      labs: [],
      lab: {},

      subs: [],
      sub: {},

      forms: [],
      form: {},

      priceRules: [
        (v) => !!v || 'Campo obligatorio',
        (v) => {
          return /^[0-9]+$/.test(v) || 'Unicamente números';
        },
      ],
      requiredRule: [(v) => !!v || 'Campo obligatorio'],
      requiredSelect: [(v) => !!v.name || 'Campo requerido'],
      cargando: true,
      radios: null,
      dialog: false,
      headers: [
        {
          text: 'Laboratorio',
          align: 'start',
          sortable: false,
          value: 'Labs.name',
          class: 'HeadLabs',
        },
        {
          text: 'Producto',
          align: 'start',
          value: 'name',
          sortable: false,
          class: 'HeadProduct',
        },
        {
          text: 'Sustancia',
          align: 'start',
          value: 'Subs.name',
          sortable: false,
        },
        {
          text: 'Forma farmacéutica',
          align: 'start',
          value: 'Forms.name',
          sortable: false,
        },
        {
          text: 'Presentación',
          align: 'start',
          value: 'presentation',
          sortable: false,
          class: 'HeadPresentation',
        },
        {
          text: 'Precio',
          align: 'start',
          value: 'price_format',
          sortable: false,
          class: 'HeadPrice',
        },
        {
          text: 'CUM',
          align: 'start',
          value: 'cum',
          sortable: false,
        },
        {
          text: 'Activo',
          align: 'center',
          value: 'active',
          sortable: false,
          class: 'HeadActive',
        },
        {
          text: 'Acción',
          align: 'center',
          value: 'actions',
          sortable: false,
          class: 'HeadAction',
        },
      ],

      products: [],

      editedIndex: -1,
      editedItem: {
        div_price: '',
        name_price: '',
        subs_price: '',
        form_price: '',
        present_price: '',
        price_price: '',
        cum_price: '',
        atc_price: '',
        active_price: '',
        price_id: '',
      },
      defaultItem: {
        div_price: '',
        name_price: '',
        subs_price: '',
        form_price: '',
        present_price: '',
        price_price: '',
        cum_price: '',
        atc_price: '',
        active_price: '',
        price_id: '',
      },
      mes: '',
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'CREAR NUEVO PRODUCTO'
        : 'EDITAR PRODUCTO';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    let fecha = new Date();
    fecha.setMonth(fecha.getMonth() - 1);
    this.mes = fecha.toLocaleDateString('es-ES', {
      month: 'long',
      year: 'numeric',
    });
    this.list();
    this.listLabs();
    this.listSubs();
    this.listForms();
  },

  methods: {
    listLabs() {
      this.axios({ url: 'laboratorio/listActiveLabs' })
        .then((response) => {
          this.labs = response.data;
        })
        .catch((error) => {
          // if (error.response.status === 403) {
          //   localStorage.removeItem('auth');
          //   localStorage.removeItem('full_name');
          //   this.$router.push({ name: 'Login' });
          // }
        });
    },
    listSubs() {
      this.axios({ url: 'substancia/listActiveSubs' })
        .then((response) => {
          this.subs = response.data;
        })
        .catch((error) => {
          // if (error.response.status === 403) {
          //   localStorage.removeItem('auth');
          //   localStorage.removeItem('full_name');
          //   this.$router.push({ name: 'Login' });
          // }
        });
    },
    listForms() {
      this.axios({ url: 'forma/listActiveForms' })
        .then((response) => {
          this.forms = response.data;
        })
        .catch((error) => {
          // if (error.response.status === 403) {
          //   localStorage.removeItem('auth');
          //   localStorage.removeItem('full_name');
          //   this.$router.push({ name: 'Login' });
          // }
        });
    },

    list() {
      this.axios({ url: 'precios/listPrices' })
        .then((response) => {
          this.products = response.data;
          this.products.forEach((item) => {
            item['active'] = item['active'] === '1' ? true : false;
            item['price_format'] = item['price'].toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          });
          this.cargando = false;
        })
        .catch((error) => {
          switch (error.response.status) {
            case 403:
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
              break;
            default:
              swal({
                title: 'Error',
                text: 'Error en el servidor',
                icon: 'error',
              });
              break;
          }
          this.cargando = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.lab = item.Labs;
      this.sub = item.Subs;
      this.form = item.Forms;
      this.editedItem.name_price = item.name;
      this.editedItem.present_price = item.presentation;
      this.editedItem.price_price = item.price;
      this.editedItem.cum_price = item.cum;
      this.editedItem.atc_price = item.atc;
      this.editedItem.active_price = item.active;
      this.editedItem.price_id = item.price_id;
      this.dialog = true;
    },

    close() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.lab = {};
      this.sub = {};
      this.form = {};
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.div_price = this.lab.division_id;
      this.editedItem.subs_price = this.sub.substance_id;
      this.editedItem.form_price = this.form.form_id;
      this.editedItem.user_name = localStorage.getItem('full_name');
      if (this.editedIndex > -1) {
        this.axios({
          url: 'precios/editPrice',
          method: 'put',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'El producto se ha editado correctamente!!',
                  text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      } else {
        this.axios({
          url: 'precios/addPrice',
          method: 'post',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Nuevo producto creado!!',
                  text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      }
      this.close();
    },

    cargarArchivo() {
      document.getElementById('fileUpload').click();
    },

    uploadFile() {
      var formData = new FormData();
      var imagefile = document.querySelector('#fileUpload');
      formData.append('file', imagefile.files[0]);
      formData.append('user_name', localStorage.getItem('full_name'));
      this.axios({
        url: 'cargar/addPrices',
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              swal({
                title: 'Carga satisfactoria!!',
                text: 'Los datos están listos para ser consultados',
                icon: 'success',
                button: 'OK',
              });
          }
          this.list();
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              swal({
                title: 'Advertencia',
                text: error.response.data['message'],
                icon: 'warning',
              });
              break;
            case 403:
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
              break;
            default:
              swal({
                title: 'Error',
                text: 'Error en el servidor',
                icon: 'error',
              });
              break;
          }
          this.cargando = false;
        });
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  max-width: 290px;
}
</style>
