var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-row',{staticClass:"pt-10",attrs:{"xs12":"","sm12":"","md6":"","lg12":""}},[_c('v-col',{staticClass:"contentUsurios",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"ma-3 mt-5 pt-5"},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mt-n15",attrs:{"height":"80","width":"80","elevation":"10","tile":""}},[_c('v-sheet',{attrs:{"color":"blue darken-4","rounded":"","height":"80","width":"80"}},[_c('v-icon',{attrs:{"dark":"","large":""}},[_vm._v("mdi-store")])],1)],1),_c('v-list-item-content',[_c('div',{staticClass:"headline text-left secondary--text mt-n4 fontFuturaMedium"},[_vm._v(" LABORATORIOS ")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.laboratorios,"items-per-page":5,"loading":_vm.cargando,"search":_vm.search,"loading-text":"Cargando... Por favor espere","align":"center"},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"blue darken-4","disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actions)+" "),_c('v-btn',{attrs:{"icon":"","medium":"","color":"success"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-lead-pencil")])],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" No hay registros por mostrar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.list}},[_vm._v(" Reset ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"success","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Crear nuevo "),_c('v-icon',{staticClass:"medium ml-3"},[_vm._v("mdi-store")])],1),_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-text-search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.rule,"label":"Laboratorio"},model:{value:(_vm.editedItem.name_lab),callback:function ($$v) {_vm.$set(_vm.editedItem, "name_lab", $$v)},expression:"editedItem.name_lab"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[[_c('v-checkbox',{attrs:{"color":"blue darken-4","label":"Activo"},model:{value:(_vm.editedItem.active_lab),callback:function ($$v) {_vm.$set(_vm.editedItem, "active_lab", $$v)},expression:"editedItem.active_lab"}})]],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-4","dark":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.isFormValid},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }