<template>
  <div>
    <pharma-form-component></pharma-form-component>
  </div>
</template> 

<script>
import PharmaFormComponent from '../components/PharmaFormComponent.vue'
export default {
  components: {
    PharmaFormComponent,
  }
}
</script>
