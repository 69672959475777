<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet color="blue darken-4" rounded height="80" width="80">
                  <v-icon dark large>mdi-store</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  LABORATORIOS
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="laboratorios"
              :items-per-page="5"
              :loading="cargando"
              :search="search"
              loading-text="Cargando... Por favor espere"
              align="center"
            >
              <!-- mobile-breakpoint="1025" -->
              <template v-slot:[`item.active`]="{ item }">
                <!-- {{ item.active }} -->
                <v-simple-checkbox
                  color="blue darken-4"
                  v-model="item.active"
                  disabled
                ></v-simple-checkbox>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                {{ item.actions }}
                <v-btn icon medium color="success" @click="editItem(item)"
                  ><v-icon>mdi-lead-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                  No hay registros por mostrar
                </v-alert>
                <v-btn color="primary" @click="list"> Reset </v-btn>
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Crear nuevo
                        <v-icon class="medium ml-3">mdi-store</v-icon>
                      </v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-text-search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mr-5"
                      ></v-text-field>
                    </template>
                    <v-form ref="form" v-model="isFormValid">
                      <v-card>
                        <v-card-title>
                          <span class="headline primary--text">{{
                            formTitle
                          }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.name_lab"
                                  :rules="rule"
                                  label="Laboratorio"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <template>
                                  <v-checkbox
                                    color="blue darken-4"
                                    v-model="editedItem.active_lab"
                                    label="Activo"
                                  ></v-checkbox>
                                </template>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red darken-4" dark @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save"
                            :disabled="!isFormValid"
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      isFormValid: false,
      cargando: true,
      radios: null,
      dialog: false,
      rule: [(value) => !!value || 'Campo obligatorio'],
      headers: [
        {
          text: 'Laboratorio',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Activo',
          align: 'center',
          value: 'active',
          sortable: false,
        },
        {
          text: 'Acción',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],

      laboratorios: [],

      editedIndex: -1,
      editedItem: {
        name_lab: '',
        active_lab: '',
        division_id: '',
        user_name: '',
      },
      defaultItem: {
        name_lab: '',
        active_lab: '',
        division_id: '',
        user_name: '',
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? 'CREAR NUEVO LABORATORIO'
        : 'EDITAR LABORATORIO';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.list();
  },

  methods: {
    list() {
      this.axios({ url: 'laboratorio/listLabs' })
        .then((response) => {
          this.laboratorios = response.data;
          this.laboratorios.forEach((item) => {
            item['active'] = item['active'] === '1' ? true : false;
          });
          this.cargando = false;
        })
        .catch((error) => {
          switch (error.response.status) {
            case 403:
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
              break;
            default:
              swal({
                title: 'Error',
                text: 'Error en el servidor',
                icon: 'error',
              });
              break;
          }
          this.cargando = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.laboratorios.indexOf(item);
      this.editedItem.name_lab = item.name;
      this.editedItem.active_lab = item.active;
      this.editedItem.division_id = item.division_id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.name_lab = this.editedItem.name_lab.toUpperCase();
      this.editedItem.user_name = localStorage.getItem('full_name');
      if (this.editedIndex > -1) {
        this.axios({
          url: 'laboratorio/editLab',
          method: 'put',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Ha editado un laboratorio!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      } else {
        this.axios({
          url: 'laboratorio/addLab',
          method: 'post',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Nuevo laboratorio creado!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      }
      this.close();
    },
  },
};
</script>

<style scoped>
/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
</style>
