<template>
  <div>
    <laboratories-component></laboratories-component>
  </div>
</template> 

<script>
import LaboratoriesComponent from '../components/LaboratoriesComponent.vue'
export default {
  components: {
    LaboratoriesComponent,
  }
}
</script>
  