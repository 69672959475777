import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Embed from 'v-video-embed'

Vue.config.productionTip = false;
window.onunload = () => {
  // Clear the local storage
  window.MyStorage.clear()
}

Vue.use(Embed);
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
