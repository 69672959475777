<template>
  <div>
    <substances-component></substances-component>
    
  </div>
</template> 

<script>
import SubstancesComponent from '../components/SubstancesComponent.vue'
export default {
  components: {
    SubstancesComponent,
  }
}
</script>
