<template>
  <v-container>
    <v-layout>
      <v-row xs12 sm12 md6 lg12 class="pt-10">
        <v-col cols="12" sm="12" md="12" class="contentUsurios">
          <!-- pa-sm-0 mx-md-auto -->
          <v-card class="ma-3 mt-5 pt-5">
            <v-list-item>
              <v-list-item-avatar
                height="80"
                width="80"
                elevation="10"
                tile
                class="mt-n15"
              >
                <v-sheet color="blue darken-4" rounded height="80" width="80">
                  <v-icon dark large>mdi-account-group</v-icon>
                </v-sheet>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="headline text-left secondary--text mt-n4 fontFuturaMedium"
                >
                  CLIENTES
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-data-table
              :headers="headers"
              :items="clientes"
              class="elevation-1"
              :loading="cargando"
              loading-text="Cargando... Por favor espere"
              :items-per-page="5"
              :search="search"
              align="center"
              width="70%"
            >
              <!-- mobile-breakpoint="1025" -->

              <template v-slot:[`item.actions`]="{ item }">
                {{ item.actions }}
                <v-btn icon medium color="success" @click="editItem(item)"
                  ><v-icon>mdi-lead-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="error" icon="warning">
                  No hay registros por mostrar
                </v-alert>
                <v-btn color="primary" @click="list"> Reset </v-btn>
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        dark
                        class="mb-2 ml-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Ingresar nuevo
                        <v-icon class="medium ml-3">mdi-account-group</v-icon>
                      </v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-text-search"
                        label="Buscar"
                        single-line
                        hide-details
                        class="mr-5"
                      ></v-text-field>
                    </template>
                    <v-form ref="form" v-model="isFormValid">
                      <v-card>
                        <v-card-title>
                          <span class="headline primary--text">{{
                            formTitle
                          }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.nit_client"
                                  label="NIT"
                                  :rules="nitRules"
                                  counter
                                  maxlength="15"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.comp_name_client"
                                  label="Empresa"
                                  :rules="empresaRules"
                                  counter
                                  maxlength="100"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.contact_name_client"
                                  label="Contacto"
                                  :rules="contactRules"
                                  counter
                                  maxlength="50"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field
                                  v-model="editedItem.email_client"
                                  label="Email"
                                  :rules="emailRules"
                                  counter
                                  maxlength="50"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red darken-4" dark @click="close">
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="success"
                            @click="save"
                            :disabled="!isFormValid"
                          >
                            Guardar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      isFormValid: false,
      cargando: true,
      radios: null,
      dialog: false,
      emailRules: [
        (v) => !!v || 'El email es obligatorio.',
        (v) => /.+@.+\..+/.test(v) || 'El email es invalido',
      ],
      nitRules: [
        (value) => !!value || 'El NIT es obligatorio',
        (value) =>
          value.length <= 15 || 'El NIT no debe superar los 15 caracteres',
      ],
      empresaRules: [
        (value) => !!value || 'El nombre de la empresa es obligatorio',
        (value) =>
          value.length <= 100 ||
          'El nombre de la empresa no debe superar los 100 caracteres',
      ],
      contactRules: [
        (value) => !!value || 'El nombre de contacto es obligatorio',
        (value) =>
          value.length <= 50 ||
          'El nombre del contacto no debe superar los 50 caracteres',
      ],
      headers: [
        {
          text: 'NIT',
          align: 'start',
          sortable: false,
          value: 'nit',
        },
        {
          text: 'Empresa',
          align: 'start',
          value: 'company_name',
          sortable: false,
        },
        {
          text: 'Contacto',
          align: 'start',
          value: 'contact_name',
          sortable: false,
        },
        {
          text: 'Email',
          align: 'start',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Acción',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],

      clientes: [],

      editedIndex: -1,
      editedItem: {
        nit_client: '',
        comp_name_client: '',
        contact_name_client: '',
        email_client: '',
        user_name: '',
        client_id: '',
      },
      defaultItem: {
        nit_client: '',
        comp_name_client: '',
        contact_name_client: '',
        email_client: '',
        user_name: '',
        client_id: '',
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'CREAR NUEVO CLIENTE' : 'EDITAR CLIENTE';
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.list();
  },

  methods: {
    list() {
      this.axios({ url: 'cliente/listClients' })
        .then((response) => {
          this.clientes = response.data;
          this.clientes.forEach((item) => {
            item['active'] = item['active'] === '1' ? true : false;
          });
          this.cargando = false;
        })
        .catch((error) => {
          switch (error.response.status) {
            case 403:
              localStorage.removeItem('auth');
              localStorage.removeItem('full_name');
              this.$router.push({ name: 'Login' });
              break;
            default:
              swal({
                title: 'Error',
                text: 'Error en el servidor',
                icon: 'error',
              });
              break;
          }
          this.cargando = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem.nit_client = item.nit;
      this.editedItem.comp_name_client = item.company_name;
      this.editedItem.contact_name_client = item.contact_name;
      this.editedItem.email_client = item.email;
      this.editedItem.client_id = item.client_id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.user_name = localStorage.getItem('full_name');
      this.editedItem.comp_name_client = this.editedItem.comp_name_client.toUpperCase();
      this.editedItem.contact_name_client = this.editedItem.contact_name_client.toUpperCase();
      if (this.editedIndex > -1) {
        this.axios({
          url: 'cliente/editClient',
          method: 'put',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Ha editado un cliente!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      } else {
        this.axios({
          url: 'cliente/addClient',
          method: 'post',
          data: this.editedItem,
        })
          .then((response) => {
            switch (response.status) {
              case 200:
                swal({
                  title: 'Nuevo cliente creado!!',
                  // text: 'Los datos están listos para ser consultados',
                  icon: 'success',
                  button: 'OK',
                });
            }
            this.list();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                swal({
                  title: 'Advertencia',
                  text: error.response.data['message'],
                  icon: 'warning',
                });
                break;
              case 403:
                localStorage.removeItem('auth');
                localStorage.removeItem('full_name');
                this.$router.push({ name: 'Login' });
                break;
              default:
                swal({
                  title: 'Error',
                  text: 'Error en el servidor',
                  icon: 'error',
                });
                break;
            }
            this.cargando = false;
          });
      }
      this.close();
    },
  },
};
</script>

<style scoped>
/* // -->  RESPONSIVE  <-- */

/* Mobile Portrait */
@media (max-width: 767px) and (min-width: 300px) {
  v-container {
    padding: 12px !important;
  }
  .ma-3.mt-5.pt-5 {
    margin: 12px !important;
  }
}
</style>
